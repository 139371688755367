import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '0ef06d02fc26be913ec1',
    cluster: 'eu',
    encrypted: true
});
